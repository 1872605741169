export const nameSearchTemplate = `contains(concat(concat(tolower(UserFirstName), ' '), tolower(UserLastName)), tolower('{0}')) or contains(tolower(WorkspaceName), tolower('{0}'))`;
export const idSearchTemplate = ` or Id eq {0}`;
export const statusFilterTemplate = `StatusId eq {0}`;
export const startDateFilterTemplate = `UnixStartsAtWithTimezone eq {0}`;
export const startAndEndDateFilterTemplate = `UnixStartsAtWithTimezone ge {0} and UnixStartsAtWithTimezone le {1}`;
export const dateFormat = "DD-MM-YYYY";

export const roomBookingsTableHiddenColumns = [
  "startsAt",
  "unixStartsAtWithTimezone",
  "createdAt",
  "confirmedAt",
  "endsAt",
  "cancelledAt",
  "expiresAt",
  "rejectedAt",
  "statusName",
  "bookingAmount",
  "bookingTypeName",
  "userId",
  "userLastName",
  "userEmail",
  "userPhone",
  "workspaceCityName",
  "workspaceCategoryId",
  "workspaceCategoryName",
  "currency",
  "timezone",
];
